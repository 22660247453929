import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Subscribe from '../components/Forms/Subscribe';

export default ({ data: { site } }) => (
  <Layout site={site} noFooter>
    <Subscribe />
  </Layout>
);

export const pageQuery = graphql`
  query {
    site {
      ...site
    }
  }
`;
